import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import Loading from "../../common/components/loading/loading";

const DailyStorageLineGraph = ({
  data,
  dateField,
  valueField,
  title,
  isLoading,
}) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    data.sort((a, b) => new Date(a[dateField]) - new Date(b[dateField]));

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 40, right: 80, bottom: 60, left: 70 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Parse dates and group by year
    const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S");

    const groupedData = d3.group(data, (d) => {
      const date = parseDate(d[dateField]);
      return date.getFullYear();
    });

    // Prepare data for plotting
    const plotData = Array.from(groupedData, ([year, values]) => {
      return {
        year: year,
        values: values.map((d) => {
          const date = parseDate(d[dateField]);
          return {
            date: new Date(2000, date.getMonth(), date.getDate()), // Use a common year (2000) for all data points
            inventory: d[valueField],
          };
        }),
      };
    });

    // Set up scales
    const xScale = d3
      .scaleTime()
      .domain([new Date(2000, 0, 1), new Date(2000, 11, 31)]) // Full year range
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d[valueField])])
      .nice()
      .range([innerHeight, 0]);

    // Color scale for different years
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // Create line generator
    const line = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.inventory))
      .defined((d) => !isNaN(d.inventory)); // Handle any potential NaN values

    // Find the most recent year
    const mostRecentYear = d3.max(plotData, (d) => d.year);

    // Draw lines for each year
    plotData.forEach((yearData) => {
      const path = line(yearData.values);
      chartSvg
        .append("path")
        .datum(yearData.values)
        .attr("fill", "none")
        .attr(
          "stroke",
          yearData.year === mostRecentYear ? "red" : colorScale(yearData.year)
        )
        .attr("stroke-width", yearData.year === mostRecentYear ? 2 : 1)
        .attr("d", path)
        .attr(
          "stroke-dasharray",
          yearData.year === mostRecentYear ? "0 0" : "2 2"
        );
    });

    // Add X axis
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(d3.timeMonth.every(1))
          .tickFormat(d3.timeFormat("%b"))
      );

    // Add Y axis
    chartSvg.append("g").call(d3.axisLeft(yScale));

    // Add Y axis label
    chartSvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - innerHeight / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("font-size", "14px")
      .text("Bcf")
      .attr("fill", "grey");

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text(title)
      .attr("fill", "grey");

    // Add legend at the bottom
    const legendHeight = 20; // Height for the legend area
    const legendLineWidth = 20; // Width of each legend line
    const legendItemWidth = 70; // Width of each legend item (line + text)

    // Extend the SVG height to accommodate the legend
    svg.attr("height", parentHeight + legendHeight + 10); // Added extra 10px for padding

    // Calculate total width of legend items
    const totalLegendWidth = plotData.length * legendItemWidth;

    const legend = chartSvg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 12)
      .attr("fill", "grey")
      .attr("text-anchor", "start")
      .attr(
        "transform",
        `translate(${(innerWidth - totalLegendWidth) / 2}, ${
          innerHeight + margin.bottom - legendHeight
        })`
      );

    plotData.forEach((yearData, i) => {
      const legendItem = legend
        .append("g")
        .attr("transform", `translate(${i * legendItemWidth}, 0)`);

      legendItem
        .append("line")
        .attr("x1", 0)
        .attr("y1", legendHeight / 2)
        .attr("x2", legendLineWidth)
        .attr("y2", legendHeight / 2)
        .attr(
          "stroke",
          yearData.year === mostRecentYear ? "red" : colorScale(yearData.year)
        )
        .attr("stroke-width", yearData.year === mostRecentYear ? 2 : 1)
        .attr(
          "stroke-dasharray",
          yearData.year === mostRecentYear ? "0 0" : "2 2"
        );

      legendItem
        .append("text")
        .attr("x", legendLineWidth + 5)
        .attr("y", legendHeight / 2)
        .attr("dy", "0.35em")
        .text(yearData.year);
    });
  }, [data, dateField, title, valueField]);

  useEffect(() => {
    !isLoading && renderChart();
  }, [renderChart, isLoading]);

  useEffect(() => {
    const handleResize = () => {
      !isLoading && renderChart();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;
  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
};

export default DailyStorageLineGraph;

import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import Loading from "../../common/components/loading/loading";

const HistoricalSettlementsGraph = ({ data, title, isLoading }) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    if (!svg.node()) {
      return;
    }

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 30, right: 0, bottom: 50, left: 50 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${parentWidth} ${parentHeight}`)
      .attr("preserveAspectRatio", "none")
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Parse date
    //const parseDate = d3.utcParse("%Y-%m-%dT%H:%M:%SZ");

    data.forEach((d) => {
      //d.marketDate = parseDate(d.marketDate);
      d.marketDate = new Date(d.marketDate);
    });

    //sort the data so the lines draw properly.
    data.sort((a, b) => a.marketDate - b.marketDate);

    // Group data by termName
    const groupedData = d3.group(data, (d) => d.termName);

    // Set up scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d.marketDate))
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([
        d3.min(data, (d) => d.settlePrice),
        d3.max(data, (d) => d.settlePrice),
      ])
      .nice()
      .range([innerHeight, 0]);

    // Create line generator
    const line = d3
      .line()
      .x((d) => xScale(d.marketDate))
      .y((d) => yScale(d.settlePrice));

    // Color scale for different terms
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // Add X axis
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale));

    // Add Y axis
    chartSvg
      .append("g")
      .call(d3.axisLeft(yScale).tickFormat((d) => `$${d.toFixed(2)}`)); // Format y-axis labels as currency

    // Add gridlines
    chartSvg
      .append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(yScale).tickSize(-innerWidth).tickFormat(""))
      .attr("stroke-opacity", 0.1);

    // Draw lines
    groupedData.forEach((values, key) => {
      chartSvg
        .append("path")
        .datum(values)
        .attr("fill", "none")
        .attr("stroke", colorScale(key))
        .attr("stroke-width", 2)
        .attr("d", line);
    });

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text(title)
      .attr("fill", "grey");

    // Add legend
    const legendSpacing = 100; // Adjust this value to change the spacing between legend items
    const legendLineLength = 20; // Length of the line in the legend

    const legend = chartSvg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        `translate(${innerWidth / 2}, ${-margin.top / 2 + 10})`
      )
      .selectAll("g")
      .data(groupedData.keys())
      .enter()
      .append("g")
      .attr(
        "transform",
        (d, i) =>
          `translate(${
            i * legendSpacing - ((groupedData.size - 1) * legendSpacing) / 2
          }, 0)`
      );

    legend
      .append("line")
      .attr("x1", -legendLineLength / 2)
      .attr("x2", legendLineLength / 2)
      .attr("stroke", colorScale)
      .attr("stroke-width", 2);

    legend
      .append("text")
      .attr("x", legendLineLength * 2 - 5)
      .attr("y", 3)
      .attr("fill", "grey")
      .text((d) => d);
  }, [data, title]);

  useEffect(() => {
    !isLoading && renderChart();
  }, [renderChart, isLoading]);

  useEffect(() => {
    const innerChartRef = svgRef;

    const handleResize = () => {
      !isLoading && renderChart();
    };

    const observer = new ResizeObserver(handleResize);
    if (innerChartRef.current) {
      observer.observe(innerChartRef.current);
    }

    return () => {
      if (innerChartRef.current) {
        observer.unobserve(innerChartRef.current);
      }
    };
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;
  return <svg ref={svgRef} width="100%" height="100%"></svg>;
};

export default HistoricalSettlementsGraph;

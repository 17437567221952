import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import Loading from "../../common/components/loading/loading";

const MonthlyOutageForecastBarGraph = ({ data, isLoading }) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 60, right: 20, bottom: 60, left: 60 }; // Increased top margin for legend

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Define energy categories (excluding dateAdded and marketDate)
    const friendlyNames = {
      biomassAndOther: "Biomass & Other",
      cc: "Combined Cycle",
      coal: "Coal",
      cogen: "Cogeneration",
      dualFuel: "Dual Fuel",
      energyStorage: "Energy Storage",
      gfs: "Gas-Fired Steam",
      hydro: "Hydro",
      mbo: "Misc. Base Load",
      sc: "Simple Cycle",
      solar: "Solar",
      wind: "Wind",
    };

    const energyCategories = [
      "biomassAndOther",
      "cc",
      "coal",
      "cogen",
      "dualFuel",
      "energyStorage",
      "gfs",
      "hydro",
      "mbo",
      "sc",
      "solar",
      "wind",
    ];

    // Stack the data
    const stack = d3
      .stack()
      .keys(energyCategories)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    const stackedData = stack(data);

    // Set up scales
    const parseDate = d3.timeParse("%b %Y");
    const sortedMonths = data
      .map((d) => d.month)
      .sort((a, b) => parseDate(a) - parseDate(b));

    const xScale = d3
      .scaleBand()
      .domain(sortedMonths)
      .range([0, innerWidth])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(stackedData, (d) => d3.max(d, (d) => d[1]))])
      .nice()
      .range([innerHeight, 0]);

    // Color scale for different energy categories
    const colorScale = d3
      .scaleOrdinal(d3.schemePaired)
      .domain(energyCategories);

    // Create and add the bar groups
    const barGroups = chartSvg
      .selectAll(".bar-group")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("class", "bar-group")
      .attr("fill", (d) => colorScale(d.key));

    // Create and add the bars
    barGroups
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.data.month))
      .attr("y", (d) => yScale(d[1]))
      .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
      .attr("width", xScale.bandwidth());

    // Add X axis
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    // Add Y axis
    chartSvg.append("g").call(d3.axisLeft(yScale));

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text("AESO Monthly Outage Forecast")
      .attr("fill", "grey");

    // Add Y axis label
    chartSvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - innerHeight / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Energy (MW)")
      .attr("fill", "grey");

    // Add legend
    const legendItemsPerRow = Math.ceil(energyCategories.length / 2);
    const legendSpacing = innerWidth / legendItemsPerRow;
    const legendBlockSize = 10;
    const legendTextOffset = 5; // Space between rectangle and text
    const legendRowHeight = 20; // Height between rows

    const legend = chartSvg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "start")
      .attr("transform", `translate(0, ${-margin.top / 2 + 5})`)
      .selectAll("g")
      .data(energyCategories)
      .enter()
      .append("g")
      .attr(
        "transform",
        (d, i) =>
          `translate(${(i % legendItemsPerRow) * legendSpacing}, ${
            Math.floor(i / legendItemsPerRow) * legendRowHeight
          })`
      )
      .attr("fill", "grey");

    legend
      .append("rect")
      .attr("width", legendBlockSize)
      .attr("height", legendBlockSize)
      .attr("fill", colorScale);

    legend
      .append("text")
      .attr("x", legendBlockSize + legendTextOffset)
      .attr("y", legendBlockSize / 2)
      .attr("dy", "0.35em")
      .text((d) => friendlyNames[d] || d);
  }, [data]);

  useEffect(() => {
    !isLoading && renderChart();
  }, [renderChart, isLoading]);

  useEffect(() => {
    const handleResize = () => {
      !isLoading && renderChart();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;
  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
};

export default MonthlyOutageForecastBarGraph;

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  getWindLongTermForecastByMarketDate,
  getSolarLongTermForecastByMarketDate,
  getPoolPriceByMarketDate,
  getDailyPoolPriceAverages,
  getInternalLoadByMarketDate,
  getDailyInternalLoadAverages,
  getSummary,
  //getDailyOutageReport,
  getMonthlyOutageForecastReport,
  getCurrentGenerationByMarketDateFuelType,
} from "../../common/services/marketdata/aesoService";
import {
  getSummaryReport,
  getCalSettlementsByMarketDateRange,
  getSpotSettlementsByMarketDateRange,
} from "../../common/services/marketdata/ngxSettlementService";
import {
  getRateOfLastResortByDateRange,
  getRegulatedRateOptionByDateRange,
} from "../../common/services/marketdata/enmaxRateService";
import { getTcDailyStorage } from "../../common/services/marketdata/tcGasSummaryService";
import { getEiaDailyStorage } from "../../common/services/marketdata/eiaGasSummaryService";
import { getMarkets } from "../../common/services/markets/marketService";
import AesoSummaryTable from "./aesoSummaryTable";
import AbStorageSummaryTable from "./abStorageSummaryTable";
import HourlyForecastLineGraph from "./hourlyForecastLineGraph";
import DailyForecastBarGraph from "./dailyForecastBarGraph";
import HourlyElectricityPricingAndLoadGraph from "./hourlyElectricityPricingAndLoadGraph";
import DailyElectricityPricingAndLoadGraph from "./dailyElectricityPricingAndLoadGraph";
import DailyElectricityPricingAndLoadDeltaGraph from "./dailyElectricityPricingAndLoadDeltaGraph";
import YearlyElectricityPricingAndLoadGraph from "./yearlyElectricityPricingAndLoadGraph";
import SettlementsTable from "./settlementsTable";
import HistoricalSettlementsGraph from "./historicalSettlementsGraph";
import MonthlyOutageForecastBarGraph from "./monthlyOutageForecastBarGraph";
import NaturalGasPoolPriceLineGraph from "./naturalGasPoolPriceLineGraph";
import HistoricalHeatRateLineGraph from "./historicalHeatRateLineGraph";
import AesoYearlyAveragePoolPriceGraph from "./aesoYearlyAveragePoolPriceGraph";
import DailyStorageLineGraph from "./dailyStorageLineGraph";
import DailyPoolPriceLineGraph from "./dailyPoolPriceLineGraph";
import GenerationByFuelTypeGraph from "./generationByFuelTypeGraph";
import AesoPoolPriceVsEnmaxRetailLineGraph from "./aesoPoolPriceVsEnmaxRetailLineGraph";
import Input from "../../common/components/form/input";

//import Loading from "../../common/components/loading/loading";

class AlbertaPowerReport extends Component {
  state = {
    longTermWindForecast: [],
    longTermSolarForecast: [],
    poolPriceDailyAverages: [],
    powerSettles: [],
    gasSettles: [],
    calPowerSettles: [],
    calGasSettles: [],
    spotGasSettles: [],
    poolPrices: [],
    internalLoad: [],
    internalLoadDailyAverages: [],
    monthlyOutageForecast: [],
    aesoSummaryData: [],
    abDailyStorage: [],
    usDailyStorage: [],
    abStorageSummary: [],
    currentGenerationByFuelType: [],
    regulatedRateOption: [],
    rateOfLastResort: [],
    priorPriceInternalLoadData: [],
    historicalPriceInternalLoadDataMerged: [],
    aesoHistoricalLineChartData: [],
    marketDate: "",
    effectivetDate: "",
    maxDate: "",
    minDate: "2024-01-01", //Stub
    //loading indicators:
    loadingLongTermWindForecast: true,
    loadingLongTermSolarForecast: true,
    loadingPoolPriceDailyAverages: true,
    loadingPowerSettles: true,
    loadingGasSettles: true,
    loadingCalPowerSettles: true,
    loadingCalGasSettles: true,
    loadingSpotGasSettles: true,
    loadingPoolPrices: true,
    loadingInternalLoad: true,
    loadingInternalLoadDailyAverages: true,
    loadingMonthlyOutageForecast: true,
    loadingAesoSummaryData: true,
    loadingAbDailyStorage: true,
    loadingUsDailyStorage: true,
    loadingAbStorageSummary: true,
    loadingCurrentGenerationByFuelType: true,
    loadingRegulatedRateOption: true,
    loadingRateOfLastResort: true,
    loadingPriorPriceInternalLoadData: true,
    loadingHistoricalPriceInternalLoadDataMerged: true,
    loadingAesoHistoricalLineChartData: true,
  };

  async componentDidMount() {
    const marketDate = new Date().toLocaleDateString("fr-CA");
    this.setState({ marketDate: marketDate, maxDate: marketDate });
    await this.loadData(marketDate);
  }

  async loadData(marketDate) {
    //Override market date if it is current and make it prior day.
    const effectiveDate =
      marketDate === new Date().toLocaleDateString("fr-CA")
        ? new Date(
            new Date(marketDate).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            })
          ).toLocaleDateString("fr-CA")
        : marketDate;

    this.setState({ effectiveDate: effectiveDate });

    await Promise.all([
      this.loadSlowData(effectiveDate),
      this.loadFastData(effectiveDate),
    ]);
  }

  async loadFastData(effectiveDate) {
    var fromDate = new Date(effectiveDate);
    fromDate.setDate(fromDate.getDate() - 30);

    var toDate = new Date(effectiveDate);
    toDate.setDate(toDate.getDate() - 0);

    const fromDateParam = fromDate.toLocaleDateString("fr-CA", {
      timeZone: "UTC",
    });
    const toDateParam = toDate.toLocaleDateString("fr-CA", { timeZone: "UTC" });

    //Set the Forecast Date - This is so we get actuals for hte current date and the forecasts are forward looking.
    const forecastDate = new Date(effectiveDate);
    forecastDate.setDate(forecastDate.getDate() + 1);
    forecastDate.setMinutes(
      forecastDate.getMinutes() + forecastDate.getTimezoneOffset()
    );

    const forecastDateParam = forecastDate.toLocaleDateString("fr-CA");

    //set Prior year param
    const priorYear = new Date(effectiveDate);
    priorYear.setFullYear(priorYear.getFullYear() - 1);

    //Set start of 5 years ago param
    const fiveYearAgoStart = new Date(
      new Date(effectiveDate).getFullYear() - 5,
      0,
      1
    );

    //Set start of 10 years ago param
    const tenYearAgoStart = new Date(
      new Date(effectiveDate).getFullYear() - 10,
      0,
      1
    );

    const [
      longTermWindForecast,
      longTermSolarForecast,
      aesoSummaryCurrent,
      aesoSummaryPriorYear,
      poolPrices,
      internalLoad,
      monthlyOutageForecast,
      poolPriceDailyAverages,
      internalLoadDailyAverages,
      abDailyStorage,
      usDailyStorage,
      regulatedRateOption,
      rateOfLastResort,
    ] = await Promise.all([
      getWindLongTermForecastByMarketDate(forecastDateParam),
      getSolarLongTermForecastByMarketDate(forecastDateParam),
      getSummary(effectiveDate),
      getSummary(priorYear.toLocaleDateString("fr-CA", { timeZone: "UTC" })),
      getPoolPriceByMarketDate(fromDateParam, toDateParam),
      getInternalLoadByMarketDate(fromDateParam, toDateParam),
      getMonthlyOutageForecastReport(effectiveDate),
      getDailyPoolPriceAverages(effectiveDate),
      getDailyInternalLoadAverages(effectiveDate),
      getTcDailyStorage(
        fiveYearAgoStart.toLocaleDateString("fr-CA", { timeZone: "UTC" }),
        effectiveDate
      ),
      getEiaDailyStorage(
        fiveYearAgoStart.toLocaleDateString("fr-CA", { timeZone: "UTC" }),
        effectiveDate
      ),
      getRegulatedRateOptionByDateRange(
        tenYearAgoStart.toLocaleDateString("fr-CA", { timeZone: "UTC" }),
        effectiveDate
      ),
      getRateOfLastResortByDateRange(
        tenYearAgoStart.toLocaleDateString("fr-CA", { timeZone: "UTC" }),
        effectiveDate
      ),
    ]);

    const poolPriceInternalLoadDataMerged =
      await this.mergePoolPriceInternalLoad(poolPrices, internalLoad);

    const aesoSummaryData = await this.generateAesoSummary(
      aesoSummaryCurrent,
      aesoSummaryPriorYear,
      longTermWindForecast,
      longTermSolarForecast,
      effectiveDate
    );

    const abStorageSummary = await this.processStorageSummary(
      abDailyStorage,
      effectiveDate
    );

    const priorPriceInternalLoadData = poolPriceInternalLoadDataMerged.filter(
      (x) =>
        new Date(x.beginDateTimeMpt).toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) === effectiveDate
    );

    const historicalPriceInternalLoadDataMerged =
      poolPriceInternalLoadDataMerged.filter(
        (x) =>
          new Date(x.beginDateTimeMpt).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) <= effectiveDate
      );

    const aesoHistoricalLineChartData = poolPriceDailyAverages.filter(
      (x) => new Date(x.marketDate) >= fiveYearAgoStart
    );

    this.setState({
      longTermWindForecast: longTermWindForecast,
      longTermSolarForecast: longTermSolarForecast,
      poolPrices: poolPrices,
      poolPriceDailyAverages: poolPriceDailyAverages,
      internalLoad: internalLoad,
      internalLoadDailyAverages: internalLoadDailyAverages,
      aesoSummaryData: aesoSummaryData,
      monthlyOutageForecast: monthlyOutageForecast,
      abDailyStorage: abDailyStorage,
      usDailyStorage: usDailyStorage,
      abStorageSummary: abStorageSummary,
      regulatedRateOption: regulatedRateOption,
      rateOfLastResort: rateOfLastResort,
      priorPriceInternalLoadData: priorPriceInternalLoadData,
      historicalPriceInternalLoadDataMerged:
        historicalPriceInternalLoadDataMerged,
      aesoHistoricalLineChartData: aesoHistoricalLineChartData,
      //loading indicators
      loadingLongTermWindForecast: false,
      loadingLongTermSolarForecast: false,
      loadingPoolPriceDailyAverages: false,
      loadingPoolPrices: false,
      loadingInternalLoad: false,
      loadingInternalLoadDailyAverages: false,
      loadingMonthlyOutageForecast: false,
      loadingAesoSummaryData: false,
      loadingAbDailyStorage: false,
      loadingUsDailyStorage: false,
      loadingAbStorageSummary: false,
      loadingCurrentGenerationByFuelType: false,
      loadingRegulatedRateOption: false,
      loadingRateOfLastResort: false,
      loadingPriorPriceInternalLoadData: false,
      loadingHistoricalPriceInternalLoadDataMerged: false,
      loadingAesoHistoricalLineChartData: false,
    });
  }

  async loadSlowData(effectiveDate) {
    const markets = await getMarkets();
    const aesoMarket = markets.find((x) => x.name === "AESO");
    const albertaGasMarket = markets.find((x) => x.name === "AECO-C");

    //Set Cal Settles Paramters
    const calSettlesFromDate = new Date(effectiveDate);
    calSettlesFromDate.setFullYear(calSettlesFromDate.getFullYear() - 3);

    const fromTerm = new Date(effectiveDate).getFullYear();
    const toTerm = fromTerm + 4;

    //Force these longer running queries to run in parallel.
    const [
      calPowerSettlesRaw,
      calGasSettlesRaw,
      spotGasSettles,
      powerSettlesRaw,
      gasSettlesRaw,
      currentGenerationByFuelType,
    ] = await Promise.all([
      getCalSettlementsByMarketDateRange(
        aesoMarket.marketId,
        calSettlesFromDate.toLocaleDateString("fr-CA"),
        effectiveDate
      ),
      getCalSettlementsByMarketDateRange(
        albertaGasMarket.marketId,
        calSettlesFromDate.toLocaleDateString("fr-CA"),
        effectiveDate
      ),
      getSpotSettlementsByMarketDateRange(
        albertaGasMarket.marketId,
        new Date("2017-01-01").toLocaleDateString("fr-CA"), //Hard code this for now
        effectiveDate
      ),
      getSummaryReport(aesoMarket.marketId, effectiveDate),
      getSummaryReport(albertaGasMarket.marketId, effectiveDate),
      getCurrentGenerationByMarketDateFuelType(effectiveDate),
    ]);

    const powerSettlesWithHeatRates = powerSettlesRaw.map((x) => ({
      ...x,
      heatRate:
        x.current /
        gasSettlesRaw.find(
          (y) => y.beginDate === x.beginDate && y.endDate === x.endDate
        )?.current,
    }));

    const powerSettles = await this.sortAndSliceSettles(
      powerSettlesWithHeatRates
    );
    const gasSettles = await this.sortAndSliceSettles(gasSettlesRaw);

    const calPowerSettles = calPowerSettlesRaw.filter(
      (x) => x.termName >= "Cal " + fromTerm && x.termName <= "Cal " + toTerm
    );

    const calGasSettles = calGasSettlesRaw.filter(
      (x) => x.termName >= "Cal " + fromTerm && x.termName <= "Cal " + toTerm
    );

    this.setState({
      powerSettles: powerSettles,
      gasSettles: gasSettles,
      calPowerSettles: calPowerSettles,
      calGasSettles: calGasSettles,
      spotGasSettles: spotGasSettles,
      currentGenerationByFuelType: currentGenerationByFuelType,
      //loading indicators
      loadingPowerSettles: false,
      loadingGasSettles: false,
      loadingCalPowerSettles: false,
      loadingCalGasSettles: false,
      loadingSpotGasSettles: false,
    });
  }

  async sortAndSliceSettles(settles) {
    const calSettles = settles.filter((x) => x.termName.indexOf("Cal") >= 0);
    calSettles.sort((a, b) => a.termName - b.termName);

    const monthlySettles = settles.filter(
      (x) =>
        x.termName.indexOf("Cal") < 0 &&
        x.termName !== "Prior Day" &&
        x.termName !== "Same Day" &&
        x.termName !== "Day Ahead" &&
        x.termName.indexOf("D+") < 0
    );
    monthlySettles.sort(
      (a, b) => new Date(a.beginDate) - new Date(b.beginDate)
    );

    const dailySettles = settles.filter(
      (x) =>
        x.termName === "Prior Day" ||
        x.termName === "Same Day" ||
        x.termName === "Day Ahead" /*  || x.termName.indexOf('D+') >= 0 */
    );
    dailySettles.sort((a, b) => new Date(a.beginDate) - new Date(b.beginDate));

    const processedSettles = [
      ...dailySettles,
      ...monthlySettles.slice(0, 12),
      ...calSettles.slice(0, 5),
    ];

    return processedSettles;
  }

  async mergePoolPriceInternalLoad(poolPrices, internalLoad) {
    function sameDay(date1, date2) {
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      return (
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()
      );
    }

    // Merge the two datasets based on begin_datetime_mpt
    const poolPriceInternalLoadDataMerged = poolPrices.map((poolItem) => {
      const matchingInternalLoadItem = internalLoad.find(
        (internalItem) =>
          sameDay(internalItem.marketDate, poolItem.marketDate) &&
          internalItem.beginDateTimeMpt === poolItem.beginDateTimeMpt
      );
      return {
        beginDateTimeMpt: poolItem.beginDateTimeMpt,
        beginDateTimeUtc: poolItem.beginDateTimeUtc,
        albertaInternalLoad: matchingInternalLoadItem
          ? matchingInternalLoadItem.albertaInternalLoad
          : null,
        forecastAlbertaInternalLoad: matchingInternalLoadItem
          ? matchingInternalLoadItem.forecastAlbertaInternalLoad
          : null,
        poolPrice: poolItem.poolPrice,
        forecastPoolPrice: poolItem.forecastPoolPrice,
        rolling30DayAvg: poolItem.rolling30DayAvg,
        marketDate: poolItem.marketDate,
      };
    });

    return poolPriceInternalLoadDataMerged;
  }

  async processStorageSummary(abDailyStorage, effectiveDate) {
    const currentDate = new Date(effectiveDate);

    const priorDate = new Date(effectiveDate);
    priorDate.setDate(priorDate.getDate() - 1);

    const priorWeek = new Date(effectiveDate);
    priorWeek.setDate(priorWeek.getDate() - 7);

    const priorYear = new Date(effectiveDate);
    priorYear.setFullYear(priorYear.getFullYear() - 1);

    const abStorageSummary = abDailyStorage
      .filter(
        (x) =>
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(currentDate).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            }) ||
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(priorDate).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            }) ||
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(priorWeek).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            }) ||
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
            new Date(priorYear).toLocaleDateString("fr-CA", {
              timeZone: "UTC",
            })
      )
      .map((x) => ({
        ...x,
        percentFull: (100 * x.inventory) / x.capacity,
        item:
          new Date(x.gasDay).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          }) ===
          new Date(currentDate).toLocaleDateString("fr-CA", {
            timeZone: "UTC",
          })
            ? "Current Day"
            : new Date(x.gasDay).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              }) ===
              new Date(priorDate).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              })
            ? "Prior Day"
            : new Date(x.gasDay).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              }) ===
              new Date(priorWeek).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              })
            ? "Prior Week"
            : new Date(x.gasDay).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              }) ===
              new Date(priorYear).toLocaleDateString("fr-CA", {
                timeZone: "UTC",
              })
            ? "Prior Year"
            : "",
      }));

    abStorageSummary.sort((a, b) => new Date(b.gasDay) - new Date(a.gasDay));

    return abStorageSummary;
  }

  async generateAesoSummary(
    aesoSummaryCurrent,
    aesoSummaryPriorYear,
    longTermWindForecast,
    longTermSolarForecast,
    effectiveDate
  ) {
    const aesoSummaryCurrentExtended = aesoSummaryCurrent.map((data) => ({
      sortOrder:
        data.item === "Summary"
          ? 0
          : data.item === "MTD Avg"
          ? 1
          : data.item === "YTD Avg"
          ? 2
          : 999,
      ...data,
      maxPriceHourly: data.item === "Summary" ? data.maxPriceHourly : "",
      pricePrecision: 2,
      currencySymbol: "$",
      uomSymbol: "",
    }));

    const aesoLoadExtended = aesoSummaryCurrent
      .filter((x) => x.item === "Summary")
      .map((data) => ({
        sortOrder: data.item === "Summary" ? 3 : 999,
        item: "Avg Load",
        beginDateTime: data.beginDateTime,
        numericAverage: data.averageLoad,
        numericAverageYesterday: data.averageLoadYesterday,
        onPeak7x16Average: data.onPeak7x16AverageLoad,
        onPeak7x16AverageYesterday: data.onPeak7x16AverageLoadYesterday,
        offPeak7x8Average: data.offPeak7x8AverageLoad,
        offPeak7x8AverageYesterday: data.offPeak7x8AverageLoadYesterday,
        pricePrecision: 0,
        currencySymbol: "",
        uomSymbol: "MW",
      }));

    const aesoSummaryPriorYearExtended = aesoSummaryPriorYear
      .filter((x) => x.item !== "MTD Avg")
      .map((data) => ({
        sortOrder:
          data.item === "YTD Avg" ? 4 : data.item === "Summary" ? 5 : 999,
        ...data,
        item:
          data.item === "Summary"
            ? "Prior Year"
            : data.item === "YTD Avg"
            ? "YTD Prior Year"
            : "Unknown",
        maxPriceHourly: data.item === "Summary" ? data.maxPriceHourly : "",
        pricePrecision: 2,
        currencySymbol: "$",
        uomSymbol: "",
      }));

    const priorDate = new Date(effectiveDate);
    priorDate.setDate(priorDate.getDate() - 1);

    const windToday = longTermWindForecast.filter((x) => {
      const adjustedForecastDate = new Date(
        new Date(x.forecastTransactionDate).getTime() - 3600000
      );

      return (
        adjustedForecastDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) === effectiveDate
      );
    });

    const windTodayOnPeak = windToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() >= 8 &&
        new Date(x.forecastTransactionDate).getUTCHours() <= 23
    );
    const windTodayOffPeak = windToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() < 8 ||
        new Date(x.forecastTransactionDate).getUTCHours() > 23
    );

    const windYesterday = longTermWindForecast.filter((x) => {
      const adjustedForecastDate = new Date(
        new Date(x.forecastTransactionDate).getTime() - 3600000
      );

      return (
        adjustedForecastDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) ===
        priorDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        })
      );
    });

    const windYesterdayOnPeak = windYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() >= 8 &&
        new Date(x.forecastTransactionDate).getUTCHours() <= 23
    );
    const windYesterdayOffPeak = windYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() < 8 ||
        new Date(x.forecastTransactionDate).getUTCHours() > 23
    );

    const currentDate = new Date(effectiveDate);
    currentDate.setMinutes(
      currentDate.getMinutes() + currentDate.getTimezoneOffset()
    );

    const wind = [
      {
        sortOrder: 6,
        item: "Wind",
        beginDateTime: currentDate,
        numericAverage:
          windToday.length > 0
            ? windToday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / windToday.length
            : null,
        numericAverageYesterday:
          windYesterday.length > 0
            ? windYesterday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / windYesterday.length
            : null,
        onPeak7x16Average:
          windTodayOnPeak.length > 0
            ? windTodayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windTodayOnPeak.length
            : null,
        onPeak7x16AverageYesterday:
          windYesterdayOnPeak.length > 0
            ? windYesterdayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windYesterdayOnPeak.length
            : null,
        offPeak7x8Average:
          windTodayOffPeak.length > 0
            ? windTodayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windTodayOffPeak.length
            : null,
        offPeak7x8AverageYesterday:
          windYesterdayOffPeak.length > 0
            ? windYesterdayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / windYesterdayOffPeak.length
            : null,
        pricePrecision: 0,
        currencySymbol: "",
        uomSymbol: "MW",
      },
    ];

    const solarToday = longTermSolarForecast.filter((x) => {
      const adjustedForecastDate = new Date(
        new Date(x.forecastTransactionDate).getTime() - 3600000
      );

      return (
        adjustedForecastDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) === effectiveDate
      );
    });

    const solarTodayOnPeak = solarToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() >= 8 &&
        new Date(x.forecastTransactionDate).getUTCHours() <= 23
    );
    const solarTodayOffPeak = solarToday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() < 8 ||
        new Date(x.forecastTransactionDate).getUTCHours() > 23
    );

    const solarYesterday = longTermSolarForecast.filter((x) => {
      const adjustedForecastDate = new Date(
        new Date(x.forecastTransactionDate).getTime() - 3600000
      );

      return (
        adjustedForecastDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        }) ===
        priorDate.toLocaleDateString("fr-CA", {
          timeZone: "UTC",
        })
      );
    });

    const solarYesterdayOnPeak = solarYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() >= 8 &&
        new Date(x.forecastTransactionDate).getUTCHours() <= 23
    );
    const solarYesterdayOffPeak = solarYesterday.filter(
      (x) =>
        new Date(x.forecastTransactionDate).getUTCHours() < 8 ||
        new Date(x.forecastTransactionDate).getUTCHours() > 23
    );

    const solar = [
      {
        sortOrder: 7,
        item: "Solar",
        beginDateTime: currentDate,
        numericAverage:
          solarToday.length > 0
            ? solarToday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / solarToday.length
            : null,
        numericAverageYesterday:
          solarYesterday.length > 0
            ? solarYesterday.reduce((a, b) => ({ actual: a.actual + b.actual }))
                .actual / solarYesterday.length
            : null,
        onPeak7x16Average:
          solarTodayOnPeak.length > 0
            ? solarTodayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarTodayOnPeak.length
            : null,
        onPeak7x16AverageYesterday:
          solarYesterdayOnPeak.length > 0
            ? solarYesterdayOnPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarYesterdayOnPeak.length
            : null,
        offPeak7x8Average:
          solarTodayOffPeak.length > 0
            ? solarTodayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarTodayOffPeak.length
            : null,
        offPeak7x8AverageYesterday:
          solarYesterdayOffPeak.length > 0
            ? solarYesterdayOffPeak.reduce((a, b) => ({
                actual: a.actual + b.actual,
              })).actual / solarYesterdayOffPeak.length
            : null,
        pricePrecision: 0,
        currencySymbol: "",
        uomSymbol: "MW",
      },
    ];

    const aesoSummary = [
      ...aesoSummaryCurrentExtended,
      ...aesoLoadExtended,
      ...aesoSummaryPriorYearExtended,
      ...wind,
      ...solar,
    ];

    return aesoSummary;
  }

  handleDateChange = (marketDate) => {
    this.setState({
      marketDate: marketDate,
      reloading: true,
      loadingLongTermWindForecast: true,
      loadingLongTermSolarForecast: true,
      loadingPoolPriceDailyAverages: true,
      loadingPowerSettles: true,
      loadingGasSettles: true,
      loadingCalPowerSettles: true,
      loadingCalGasSettles: true,
      loadingSpotGasSettles: true,
      loadingPoolPrices: true,
      loadingInternalLoad: true,
      loadingInternalLoadDailyAverages: true,
      loadingMonthlyOutageForecast: true,
      loadingAesoSummaryData: true,
      loadingAbDailyStorage: true,
      loadingUsDailyStorage: true,
      loadingAbStorageSummary: true,
      loadingCurrentGenerationByFuelType: true,
      loadingRegulatedRateOption: true,
      loadingRateOfLastResort: true,
      loadingPriorPriceInternalLoadData: true,
      loadingHistoricalPriceInternalLoadDataMerged: true,
      loadingAesoHistoricalLineChartData: true,
    });

    (async () => {
      await this.loadData(marketDate);
    })();
  };

  render() {
    const {
      longTermWindForecast,
      longTermSolarForecast,
      powerSettles,
      gasSettles,
      calPowerSettles,
      calGasSettles,
      spotGasSettles,
      poolPriceDailyAverages,
      internalLoadDailyAverages,
      aesoSummaryData,
      monthlyOutageForecast,
      abDailyStorage,
      usDailyStorage,
      abStorageSummary,
      currentGenerationByFuelType,
      regulatedRateOption,
      rateOfLastResort,
      priorPriceInternalLoadData,
      historicalPriceInternalLoadDataMerged,
      aesoHistoricalLineChartData,
      marketDate,
      effectiveDate,
      maxDate,
      minDate,
      //loading indicators
      loadingLongTermWindForecast,
      loadingLongTermSolarForecast,
      loadingPoolPriceDailyAverages,
      loadingPowerSettles,
      loadingGasSettles,
      loadingCalPowerSettles,
      loadingCalGasSettles,
      //loadingSpotGasSettles,
      //loadingPoolPrices,
      //loadingInternalLoad,
      loadingInternalLoadDailyAverages,
      loadingMonthlyOutageForecast,
      loadingAesoSummaryData,
      loadingAbDailyStorage,
      loadingUsDailyStorage,
      loadingAbStorageSummary,
      loadingCurrentGenerationByFuelType,
      loadingRegulatedRateOption,
      loadingRateOfLastResort,
      loadingPriorPriceInternalLoadData,
      loadingHistoricalPriceInternalLoadDataMerged,
      loadingAesoHistoricalLineChartData,
    } = this.state;

    const { t } = this.props;

    const priorDate = new Date(marketDate);
    priorDate.setDate(priorDate.getDate() - 1);
    priorDate.setMinutes(
      priorDate.getMinutes() + priorDate.getTimezoneOffset()
    );

    return (
      <div className="container-fluid" style={{ height: "100%" }}>
        <div className="row" style={{ height: "5%" }}>
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <h1>{t("AlbertaEnergyDaily")}</h1>
          </div>
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <h5>{effectiveDate}</h5>
          </div>
        </div>
        <div className="row" style={{ height: "8%" }}>
          <div className="col-md-2">
            <Input
              type="date"
              min={minDate}
              max={maxDate}
              onKeyDown={(e) => e.preventDefault()}
              // onFocus={(e) => e.blur()}
              onChange={(e) => this.handleDateChange(e.currentTarget.value)}
              value={marketDate}
              label={t("MarketDate")}
            />
          </div>
        </div>
        <div
          className="row"
          style={{ overflowY: "auto", overflowX: "hidden", height: "87%" }}
        >
          <div className="col">
            <div className="row">
              <div className="col-md-8 tile" style={{ minHeight: "300px" }}>
                <AesoSummaryTable
                  summaryData={aesoSummaryData}
                  currencySymbol={"$"}
                  pricePrecision={2}
                  isLoading={loadingAesoSummaryData}
                />
              </div>
              <div className="col-md-4 tile">
                AB Natural Gas Storage (Bcf)
                <AbStorageSummaryTable
                  summaryData={abStorageSummary}
                  isLoading={loadingAbStorageSummary}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                <GenerationByFuelTypeGraph
                  data={currentGenerationByFuelType}
                  metricField="net_generation"
                  title={"Generation by Fuel Type"}
                  isLoading={loadingCurrentGenerationByFuelType}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                <GenerationByFuelTypeGraph
                  data={currentGenerationByFuelType}
                  metricField="dispatched_contingency_reserve"
                  title={"Dispatched Contingency Reserve by Fuel Type"}
                  isLoading={loadingCurrentGenerationByFuelType}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 tile" style={{ height: "300px" }}>
                {/*AESO Average 7X24 Price and Load Last 30 days*/}
                <DailyElectricityPricingAndLoadGraph
                  data={historicalPriceInternalLoadDataMerged}
                  isLoading={loadingHistoricalPriceInternalLoadDataMerged}
                />
              </div>
              <div className="col-md-4 tile" style={{ height: "300px" }}>
                {/*AESO Average 7X24 Price and Load Delta Last 30 days*/}
                <DailyElectricityPricingAndLoadDeltaGraph
                  data={historicalPriceInternalLoadDataMerged}
                  isLoading={loadingHistoricalPriceInternalLoadDataMerged}
                />
              </div>
              <div className="col-md-4 tile" style={{ height: "300px" }}>
                {/*AESO Average Hourly Electricity Pricing and Load:{" "}
                {priorDate.toUTCString().substring(0, 17)}*/}
                <HourlyElectricityPricingAndLoadGraph
                  data={priorPriceInternalLoadData}
                  title={"AESO Average Hourly Electricity Pricing and Load"}
                  isLoading={loadingPriorPriceInternalLoadData}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ minHeight: "300px" }}>
                <SettlementsTable
                  settlements={powerSettles}
                  currencySymbol={"$"}
                  pricePrecision={2}
                  showHeatRate={true}
                  title={"7X24 Alberta Forward Power CAD$/MWh (ICE NGX)"}
                  isLoading={loadingPowerSettles}
                />
              </div>
              <div className="col-md-6 tile" style={{ minHeight: "300px" }}>
                <SettlementsTable
                  settlements={gasSettles}
                  currencySymbol={"$"}
                  pricePrecision={2}
                  showHeatRate={false}
                  title={"Natural Gas Prices CAD$/GJ (ICE NGX)"}
                  isLoading={loadingGasSettles}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Alberta Power Forward Calendar Pricing*/}
                <HistoricalSettlementsGraph
                  data={calPowerSettles}
                  title={"Alberta Power Forward Calendar Pricing"}
                  isLoading={loadingCalPowerSettles}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Alberta Forward Natural Gas Calendar Pricing*/}
                <HistoricalSettlementsGraph
                  data={calGasSettles}
                  title={"Alberta Forward Natural Gas Calendar Pricing"}
                  isLoading={loadingCalGasSettles}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Historical AESO 7X24 and Ice Spot Natural Gas*/}
                <NaturalGasPoolPriceLineGraph
                  gasData={spotGasSettles}
                  poolPriceData={poolPriceDailyAverages}
                  isLoading={loadingPoolPriceDailyAverages}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Historical Heat Rates*/}
                <HistoricalHeatRateLineGraph
                  gasData={spotGasSettles}
                  poolPriceData={poolPriceDailyAverages}
                  isLoading={loadingPoolPriceDailyAverages}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*AESO 7X24 Average Prices 2017 to 2024*/}
                <AesoYearlyAveragePoolPriceGraph
                  poolPriceData={poolPriceDailyAverages}
                  isLoading={loadingPoolPriceDailyAverages}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                AESO Average 7X24 Price and Load
                <YearlyElectricityPricingAndLoadGraph
                  priceData={poolPriceDailyAverages}
                  loadData={internalLoadDailyAverages}
                  isLoading={
                    loadingPoolPriceDailyAverages ||
                    loadingInternalLoadDailyAverages
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Aeso 7 Day Hourly Wind Forecast*/}
                <HourlyForecastLineGraph
                  data={longTermWindForecast}
                  title={"AESO Hourly Wind Forecast"}
                  isLoading={loadingLongTermWindForecast}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Aeso 7 Day Hourly Solar Forecast*/}
                <HourlyForecastLineGraph
                  data={longTermSolarForecast}
                  title={"AESO Hourly Solar Forecast"}
                  isLoading={loadingLongTermSolarForecast}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Aeso 7 Day Wind Forecast*/}
                <DailyForecastBarGraph
                  data={longTermWindForecast}
                  title={"AESO Daily Wind Forecast"}
                  isLoading={loadingLongTermWindForecast}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Aeso 7 Day Solar Forecast*/}
                <DailyForecastBarGraph
                  data={longTermSolarForecast}
                  title={"AESO Daily Solar Forecast"}
                  isLoading={loadingLongTermSolarForecast}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                <DailyStorageLineGraph
                  data={abDailyStorage}
                  dateField={"gasDay"}
                  valueField={"inventory"}
                  title={"Alberta Natural Gas Storage Inventory"}
                  isLoading={loadingAbDailyStorage}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                <DailyStorageLineGraph
                  data={usDailyStorage.filter(
                    (x) => x.name === "total lower 48 states"
                  )}
                  dateField={"currentWeek"}
                  valueField={"currentValue"}
                  title={"US Natural Gas Storage Inventory"}
                  isLoading={loadingUsDailyStorage}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                <DailyPoolPriceLineGraph
                  data={aesoHistoricalLineChartData}
                  isLoading={loadingAesoHistoricalLineChartData}
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*AESO Monthly Outage Forecast*/}
                <MonthlyOutageForecastBarGraph
                  data={monthlyOutageForecast}
                  isLoading={loadingMonthlyOutageForecast}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                <AesoPoolPriceVsEnmaxRetailLineGraph
                  poolPriceData={poolPriceDailyAverages}
                  regulatedRateData={regulatedRateOption}
                  rateOfLastResortData={rateOfLastResort}
                  isLoading={
                    loadingPoolPriceDailyAverages ||
                    loadingRegulatedRateOption ||
                    loadingRateOfLastResort
                  }
                />
              </div>
              <div className="col-md-6 tile" style={{ height: "300px" }}>
                {/*Alberta Retail Pricing Comparison*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(AlbertaPowerReport);

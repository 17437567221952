import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import Loading from "../../common/components/loading/loading";

const YearlyElectricityPriceLoadGraph = ({
  priceData,
  loadData,
  isLoading,
}) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 40, right: 80, bottom: 60, left: 70 };
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Calculate yearly averages
    const priceYearlyData = d3.rollups(
      priceData,
      (v) => ({ avgPrice: d3.mean(v, (d) => d.poolPrice) }),
      (d) => new Date(d.marketDate).getUTCFullYear()
    );

    const loadYearlyData = d3.rollups(
      loadData,
      (v) => ({ avgLoad: d3.mean(v, (d) => d.albertaInternalLoad) }),
      (d) => new Date(d.marketDate).getUTCFullYear()
    );

    const yearlyData = priceYearlyData
      .map(([year, priceValues]) => {
        const loadValues = loadYearlyData.find(
          ([loadYear]) => loadYear === year
        );
        return {
          year: year,
          avgPrice: priceValues.avgPrice,
          avgLoad: loadValues ? loadValues[1].avgLoad : null,
        };
      })
      .filter((d) => d.avgLoad !== null);

    const xScale = d3
      .scaleBand()
      .domain(yearlyData.map((d) => d.year))
      .range([0, innerWidth])
      .padding(0.1);

    const yScalePrice = d3
      .scaleLinear()
      .domain([0, d3.max(yearlyData, (d) => d.avgPrice)])
      .nice()
      .range([innerHeight, 0]);

    const yScaleLoad = d3
      .scaleLinear()
      .domain([
        d3.min(yearlyData, (d) => d.avgLoad),
        d3.max(yearlyData, (d) => d.avgLoad),
      ])
      .nice()
      .range([innerHeight, 0]);

    // Draw bars for load
    chartSvg
      .selectAll(".bar")
      .data(yearlyData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.year))
      .attr("y", (d) => yScaleLoad(d.avgLoad))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => innerHeight - yScaleLoad(d.avgLoad))
      .attr("fill", "#0096FF");

    // Draw line for price
    const linePriceGenerator = d3
      .line()
      .x((d) => xScale(d.year) + xScale.bandwidth() / 2)
      .y((d) => yScalePrice(d.avgPrice));

    chartSvg
      .append("path")
      .datum(yearlyData)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("d", linePriceGenerator);

    // Add X axis
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale).tickFormat(d3.format("d")));

    // Add Y axis for price
    const formatCurrency = d3.format("$,.2f");
    chartSvg
      .append("g")
      .call(d3.axisLeft(yScalePrice).tickFormat(formatCurrency));

    // Add Y axis for load
    chartSvg
      .append("g")
      .attr("transform", `translate(${innerWidth},0)`)
      .call(d3.axisRight(yScaleLoad));

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text("Yearly Average 7x24 Price and Load")
      .attr("fill", "grey");

    // Legend
    const legend = chartSvg
      .append("g")
      .attr("transform", `translate(${innerWidth - margin.left - 180}, 0)`);

    // Legend: Price
    legend
      .append("line")
      .attr("x1", innerWidth / 4 - 60) // Starting point of the line
      .attr("y1", 5) // Position the line vertically
      .attr("x2", innerWidth / 4 + -30) // Ending point of the line (horizontal length)
      .attr("y2", 5) // Same vertical position as the start
      .attr("stroke", "red") // Blue color
      .attr("stroke-width", 2); // Line width

    legend
      .append("text")
      .attr("x", innerWidth / 4 - 25)
      .attr("y", 10)
      .attr("font-size", "12px")
      .attr("fill", "grey")
      .text("Price");

    // Legend: Load
    legend
      .append("line")
      .attr("x1", innerWidth / 4 + 30) // Starting point of the line
      .attr("y1", 5) // Position the line vertically
      .attr("x2", innerWidth / 4 + 60) // Ending point of the line (horizontal length)
      .attr("y2", 5) // Same vertical position as the start
      .attr("stroke", "#0096FF") // Blue color
      .attr("stroke-width", 2); // Line width

    legend
      .append("text")
      .attr("x", innerWidth / 4 + 65)
      .attr("y", 10)
      .attr("font-size", "12px")
      .attr("fill", "grey")
      .text("Load");
  }, [priceData, loadData]);

  useEffect(() => {
    !isLoading && renderChart();
  }, [renderChart, isLoading]);

  useEffect(() => {
    const handleResize = () => {
      !isLoading && renderChart();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;
  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
};

export default YearlyElectricityPriceLoadGraph;

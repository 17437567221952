import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import Loading from "../../common/components/loading/loading";

const AesoPoolPriceVsEnmaxRetailLineGraph = ({
  poolPriceData,
  regulatedRateData,
  rateOfLastResortData,
  isLoading,
}) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 40, right: 80, bottom: 70, left: 70 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Parse dates and average by month
    const formatMonth = d3.timeFormat("%Y-%m");

    const averageByMonth = (data, dateField, valueField, multiplier = 1) => {
      const groupedData = d3.group(data, (d) => {
        const parsedDate = new Date(d[dateField]);
        if (!parsedDate || d[dateField] === null) {
          console.error(`Failed to parse date: ${d[dateField]}`);
          return null;
        }
        return formatMonth(parsedDate);
      });

      return Array.from(groupedData, ([key, values]) => {
        if (key === null) return null;
        return {
          marketDate: new Date(key),
          [valueField]: d3.mean(values, (d) => {
            const value = +d[valueField];
            if (isNaN(value)) {
              console.error(`Invalid ${valueField} value:`, d[valueField]);
              return null;
            }
            return value * multiplier;
          }),
        };
      }).filter((d) => d !== null);
    };

    const averagedRegulatedRateData = averageByMonth(
      regulatedRateData,
      "date",
      "price"
    );
    const averagedRateOfLastResortData = averageByMonth(
      rateOfLastResortData,
      "date",
      "unitPrice",
      10 /*True up to a common UOM*/
    );

    const minDate = new Date(
      Math.min(...averagedRegulatedRateData.map((x) => x.marketDate))
    );

    const averagedPoolPriceData = averageByMonth(
      poolPriceData.filter((x) => new Date(x.marketDate) >= minDate), //Limit the lookback to just those dates for which we have retail prices,
      "marketDate",
      "poolPrice"
    );

    // Calculate the difference for the bar graph
    const differenceData = averagedPoolPriceData
      .map((poolPrice) => {
        const date = poolPrice.marketDate;
        const rateOfLastResort = averagedRateOfLastResortData.find(
          (d) => d.marketDate.getTime() === date.getTime()
        );
        const regulatedRate = averagedRegulatedRateData.find(
          (d) => d.marketDate.getTime() === date.getTime()
        );
        const comparePrice = rateOfLastResort
          ? rateOfLastResort.unitPrice
          : regulatedRate
          ? regulatedRate.price
          : null;
        return {
          marketDate: date,
          difference:
            comparePrice !== null ? poolPrice.poolPrice - comparePrice : null,
        };
      })
      .filter((d) => d.difference !== null);

    // Set up scales
    const xScale = d3
      .scaleTime()
      .domain(
        d3.extent(
          [...averagedPoolPriceData, ...differenceData],
          (d) => d.marketDate
        )
      )
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([
        d3.min([
          ...averagedPoolPriceData.map((d) => d.poolPrice),
          ...averagedRegulatedRateData.map((d) => d.price),
          ...averagedRateOfLastResortData.map((d) => d.unitPrice),
          ...differenceData.map((d) => d.difference),
        ]),
        d3.max([
          ...averagedPoolPriceData.map((d) => d.poolPrice),
          ...averagedRegulatedRateData.map((d) => d.price),
          ...averagedRateOfLastResortData.map((d) => d.unitPrice),
          ...differenceData.map((d) => d.difference),
        ]),
      ])
      .nice()
      .range([innerHeight, 0]);

    // Create line generators
    const lineGenerator = d3
      .line()
      .x((d) => xScale(d.marketDate))
      .y((d) => yScale(d[Object.keys(d)[1]])); // Use the second property (value) for y

    // Draw lines
    const lines = [
      { data: averagedPoolPriceData, color: "#0096FF", label: "Pool Price" },
      {
        data: averagedRegulatedRateData,
        color: "springgreen",
        label: "Regulated Rate",
      },
      {
        data: averagedRateOfLastResortData,
        color: "violet",
        label: "Rate of Last Resort",
      },
    ];

    lines.forEach((line) => {
      chartSvg
        .append("path")
        .datum(line.data)
        .attr("fill", "none")
        .attr("stroke", line.color)
        .attr("stroke-width", 2)
        .attr("d", lineGenerator);
    });

    // Draw bars
    const barWidth = (innerWidth / differenceData.length) * 0.8; // 80% of available space
    const barPadding = (innerWidth / differenceData.length) * 0.15; // 10% padding on each side

    chartSvg
      .selectAll(".bar")
      .data(differenceData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.marketDate) - barWidth / 2 + barPadding)
      .attr("y", (d) => (d.difference > 0 ? yScale(d.difference) : yScale(0)))
      .attr("width", barWidth - 2 * barPadding) // Subtract padding from both sides
      .attr("height", (d) => Math.abs(yScale(d.difference) - yScale(0)))
      .attr("fill", (d) =>
        d.difference > 0 ? "rgba(0, 255, 0, 0.5)" : "rgba(255, 0, 0, 0.5)"
      );

    // Add X axis
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(d3.timeMonth.every(3))
          .tickFormat(d3.timeFormat("%b %Y"))
      )
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    // Add Y axis
    chartSvg
      .append("g")
      .call(d3.axisLeft(yScale).tickFormat((d) => `$${d.toFixed(2)}`));

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text("AESO Pool Price vs Enmax Regulated Rates")
      .attr("fill", "grey");

    // Add Y axis label
    chartSvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - innerHeight / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("font-size", "14px")
      .text("Price ($/MWh)")
      .attr("fill", "grey");

    // Add legend
    const legendItems = [
      { color: "#0096FF", label: "Pool Price" },
      { color: "springgreen", label: "Regulated Rate" },
      { color: "violet", label: "Rate of Last Resort" },
      { color: "rgba(0, 255, 0, 0.5)", label: "Positive Difference" },
      { color: "rgba(255, 0, 0, 0.5)", label: "Negative Difference" },
    ];

    const legend = chartSvg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "end")
      .attr("transform", `translate(${innerWidth}, ${-10})`);

    legendItems.forEach((item, i) => {
      const legendItem = legend
        .append("g")
        .attr("transform", `translate(${-i * 120}, 0)`);

      if (i < 3) {
        legendItem
          .append("line")
          .attr("x1", -30)
          .attr("x2", -10)
          .attr("stroke", item.color)
          .attr("stroke-width", 2);
      } else {
        legendItem
          .append("rect")
          .attr("x", -30)
          .attr("y", -5)
          .attr("width", 20)
          .attr("height", 10)
          .attr("fill", item.color);
      }

      legendItem
        .append("text")
        .attr("x", -35)
        .attr("dy", "0.32em")
        .text(item.label)
        .attr("fill", "grey");
    });
  }, [poolPriceData, regulatedRateData, rateOfLastResortData]);

  useEffect(() => {
    !isLoading && renderChart();
  }, [renderChart, isLoading]);

  useEffect(() => {
    const handleResize = () => {
      !isLoading && renderChart();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;
  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
};

export default AesoPoolPriceVsEnmaxRetailLineGraph;

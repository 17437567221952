import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import Loading from "../../common/components/loading/loading";

const ClusteredBarChart = ({ data, title, isLoading }) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    // Clear the SVG before drawing new content
    d3.select(svgRef.current).selectAll("*").remove();

    // Dimensions
    const svg = d3.select(svgRef.current);

    if (!svg.node()) {
      return;
    }

    const parentContainer = svg.node().parentNode;
    const containerWidth = parentContainer.clientWidth;
    const containerHeight = parentContainer.clientHeight;

    const margin = { top: 30, right: 30, bottom: 50, left: 60 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    // Parse date format for `forecastTransactionDate` in UTC to avoid time zone issues
    const parseDate = d3.utcParse("%Y-%m-%dT%H:%M:%SZ");

    // Format data, converting dates and calculating daily summaries
    const formattedData = data
      .filter(
        (d) =>
          d.forecastTransactionDate && d.mostLikely !== null && d.mcr !== null
      ) // Ensure valid data
      .map((d) => ({
        forecastTransactionDate: parseDate(d.forecastTransactionDate),
        mostLikely: d.mostLikely,
        mcr: d.mcr,
        actual: d.actual,
      }));

    const normalizedData = formattedData.map((d) => ({
      ...d,
      normalizedDate: d3.timeDay(d.forecastTransactionDate), // Normalize to day
    }));

    // Calculate daily summaries (avgMostLikely, maxMostLikely, maxMcr)
    const dailySummaryMap = d3.rollups(
      normalizedData,
      (v) => ({
        avgMostLikely: d3.mean(v, (d) => d.mostLikely),
        maxMostLikely: d3.max(v, (d) => d.mostLikely),
        avgActual: d3.mean(v, (d) => d.actual),
        maxMcr: d3.max(v, (d) => d.mcr),
      }),
      (d) => +d.normalizedDate // Use +d.normalizedDate to ensure consistent Map key format
    );

    // Create a Map for faster lookup of daily summaries by date
    const dailySummaryFilter = new Map(dailySummaryMap);

    // Create an array for the clustered bars data
    const barsData = Array.from(
      dailySummaryFilter,
      ([date, { avgActual, avgMostLikely, maxMostLikely, maxMcr }]) => ({
        date,
        avgActual,
        avgMostLikely,
        maxMostLikely,
        maxMcr,
      })
    );

    // Create SVG
    const chartSvg = svg
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`)
      .attr("preserveAspectRatio", "none") // Preserve aspect ratio
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Set up scales
    const xScale = d3
      .scaleBand()
      .domain(barsData.map((d) => d.date))
      .range([0, width])
      .padding(0.3); // Increased padding for spacing between clusters

    const yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(barsData, (d) =>
          Math.max(d.avgMostLikely, d.maxMostLikely, d.maxMcr)
        ),
      ])
      .nice()
      .range([height, 0]);

    // X and Y Axes
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale).tickFormat(d3.timeFormat("%Y-%m-%d")));

    chartSvg.append("g").call(d3.axisLeft(yScale));

    // Y Axis label
    chartSvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -margin.left + 10)
      .attr("text-anchor", "middle")
      .attr("font-size", "14px")
      .attr("fill", "grey")
      .text("MW/h");

    // Add gridlines
    chartSvg
      .append("g")
      .selectAll(".grid")
      .data(yScale.ticks())
      .enter()
      .append("line")
      .attr("class", "grid")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "grey")
      .attr("opacity", "25%")
      .attr("stroke-width", 1);

    // Bars for daily summaries
    chartSvg
      .selectAll(".bar.avgActual")
      .data(barsData)
      .enter()
      .append("rect")
      .attr("class", "bar avgActual")
      .attr("x", (d) => xScale(d.date) - (0 * xScale.bandwidth()) / 8 - 1) // Shift for spacing between bars in each cluster
      .attr("y", (d) => yScale(d.avgActual))
      .attr("width", xScale.bandwidth() / 4 - 1) // Width for the first bar (average)
      .attr("height", (d) => height - yScale(d.avgActual))
      .attr("fill", "green");

    chartSvg
      .selectAll(".bar.avgMostLikely")
      .data(barsData)
      .enter()
      .append("rect")
      .attr("class", "bar avgMostLikely")
      .attr("x", (d) => xScale(d.date) + (2 * xScale.bandwidth()) / 8) // Shift for spacing between bars in each cluster
      .attr("y", (d) => yScale(d.avgMostLikely))
      .attr("width", xScale.bandwidth() / 4 - 1) // Width for the first bar (average)
      .attr("height", (d) => height - yScale(d.avgMostLikely))
      .attr("fill", "red");

    chartSvg
      .selectAll(".bar.maxMostLikely")
      .data(barsData)
      .enter()
      .append("rect")
      .attr("class", "bar maxMostLikely")
      .attr("x", (d) => xScale(d.date) + (4 * xScale.bandwidth()) / 8) // Shift for the second bar (maxMostLikely)
      .attr("y", (d) => yScale(d.maxMostLikely))
      .attr("width", xScale.bandwidth() / 4 - 1) // Width for the second bar
      .attr("height", (d) => height - yScale(d.maxMostLikely))
      .attr("fill", "#0096FF");

    chartSvg
      .selectAll(".bar.maxMcr")
      .data(barsData)
      .enter()
      .append("rect")
      .attr("class", "bar maxMcr")
      .attr("x", (d) => xScale(d.date) + (6 * xScale.bandwidth()) / 8) // Shift for the third bar (maxMcr)
      .attr("y", (d) => yScale(d.maxMcr))
      .attr("width", xScale.bandwidth() / 4 - 1) // Width for the third bar
      .attr("height", (d) => height - yScale(d.maxMcr))
      .attr("fill", "grey");

    // Legend
    const legend = chartSvg
      .append("g")
      .attr("transform", `translate(${width / 2}, -10)`); // Position legend above the bars

    // Add title
    chartSvg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text(title)
      .attr("fill", "grey");

    // Legend: Avg Actial
    legend
      .append("rect")
      .attr("x", width / 4 - 145)
      .attr("y", 0)
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", "green");

    legend
      .append("text")
      .attr("x", width / 4 - 130)
      .attr("y", 10)
      .attr("font-size", "12px")
      .attr("fill", "grey")
      .text("Avg Actual");

    // Legend: Avg/Day
    legend
      .append("rect")
      .attr("x", width / 4 - 60)
      .attr("y", 0)
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", "red");

    legend
      .append("text")
      .attr("x", width / 4 - 45)
      .attr("y", 10)
      .attr("font-size", "12px")
      .attr("fill", "grey")
      .text("Avg/Day");

    // Legend: Peak Hour
    legend
      .append("rect")
      .attr("x", width / 4 + 10)
      .attr("y", 0)
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", "#0096FF");

    legend
      .append("text")
      .attr("x", width / 4 + 25)
      .attr("y", 10)
      .attr("font-size", "12px")
      .attr("fill", "grey")
      .text("Peak Hour");

    // Legend: Capacity
    legend
      .append("rect")
      .attr("x", width / 4 + 90)
      .attr("y", 0)
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", "grey");

    legend
      .append("text")
      .attr("x", width / 4 + 105)
      .attr("y", 10)
      .attr("font-size", "12px")
      .attr("fill", "grey")
      .text("Capacity");
  }, [data, title]);

  useEffect(() => {
    !isLoading && renderChart();

    return () => {
      /*         // Remove the tooltip when the component unmounts
        if (tooltipRef.current) {
            tooltipRef.current.remove();
        } */
    };
  }, [renderChart, isLoading]);

  useEffect(() => {
    const innerChartRef = svgRef;

    const handleResize = () => {
      !isLoading && renderChart();
    };

    const observer = new ResizeObserver(handleResize);
    if (innerChartRef.current) {
      observer.observe(innerChartRef.current);
    }

    // Clean up observer on component unmount
    return () => {
      if (innerChartRef.current) {
        observer.unobserve(innerChartRef.current);
      }
    };
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;
  return <svg ref={svgRef} width="100%" height="100%"></svg>;
};

export default ClusteredBarChart;

import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import Loading from "../../common/components/loading/loading";

const GenerationByFuelTypeGraph = ({ data, metricField, title, isLoading }) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 80, right: 20, bottom: 50, left: 50 };
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const fuelTypes = [
      "GAS_COGENERATION",
      "GAS_COMBINED_CYCLE",
      "GAS_GAS_FIRED_STEAM",
      "GAS_SIMPLE_CYCLE",
      "HYDRO",
      "WIND",
      "SOLAR",
      //"COAL",
      "OTHER",
      //"DUAL FUEL",
    ];

    const fuelTypeColors = {
      GAS_COGENERATION: "navy",
      GAS_COMBINED_CYCLE: "royalblue",
      GAS_GAS_FIRED_STEAM: "skyblue",
      GAS_SIMPLE_CYCLE: "turquoise",
      HYDRO: "pink",
      WIND: "green",
      SOLAR: "yellow",
      //COAL: "red",
      OTHER: "purple",
      //"DUAL FUEL": "orange",
    };

    data.sort((a, b) => new Date(a.date) - new Date(b.date));

    const groupedData = d3.group(
      data.filter((d) => d.fuel_type !== "ENERGY STORAGE"),
      (d) => new Date(d.date)
    );

    // Aggregate data for each date and fuel type
    const aggregatedData = Array.from(groupedData, ([date, values]) => {
      const fuelTypeMap = new Map();
      values.forEach((v) => {
        const fuelType =
          v.fuel_type === "GAS" ? `GAS_${v.sub_fuel_type}` : v.fuel_type;
        if (!fuelTypeMap.has(fuelType)) {
          fuelTypeMap.set(fuelType, 0);
        }
        fuelTypeMap.set(fuelType, fuelTypeMap.get(fuelType) + v[metricField]);
      });
      return { date, ...Object.fromEntries(fuelTypeMap) };
    });

    const stackedData = d3
      .stack()
      .keys(fuelTypes)
      .value((d, key) => d[key] || 0)(aggregatedData);

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(groupedData.keys()))
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(stackedData, (d) => d3.max(d, (d) => d[1]))])
      .nice()
      .range([innerHeight, 0]);

    const area = d3
      .area()
      .x((d) => xScale(d.data.date))
      .y0((d) => yScale(d[0]))
      .y1((d) => yScale(d[1]));

    chartSvg
      .selectAll("path")
      .data(stackedData)
      .join("path")
      .attr("fill", ({ key }) => fuelTypeColors[key])
      .attr("d", area);

    // Add X axis
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(d3.timeHour.every(6))
          .tickFormat(d3.timeFormat("%H:%M"))
      );

    // Add Y axis
    chartSvg.append("g").call(d3.axisLeft(yScale));

    // Y Axis label
    chartSvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -innerHeight / 2)
      .attr("y", -margin.left + 10)
      .attr("text-anchor", "middle")
      .attr("font-size", "14px")
      .attr("fill", "grey")
      .text("MW");

    // // Add title
    // chartSvg
    //   .append("text")
    //   .attr("x", innerWidth / 2)
    //   .attr("y", -margin.top / 2)
    //   .attr("text-anchor", "middle")
    //   .attr("font-size", "16px")
    //   .attr("font-weight", "bold")
    //   .text(title)
    //   .attr("fill", "grey");

    // Add legend
    const legendItemWidth = 150;
    const legendItemHeight = 20;
    const legendItemsPerRow = Math.floor(innerWidth / legendItemWidth);

    const legend = chartSvg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "start")
      .selectAll("g")
      .data(fuelTypes)
      .join("g")
      .attr(
        "transform",
        (d, i) =>
          `translate(${(i % legendItemsPerRow) * legendItemWidth}, ${
            Math.floor(i / legendItemsPerRow) * legendItemHeight -
            margin.top +
            35
          })`
      );

    legend
      .append("rect")
      .attr("width", 15)
      .attr("height", 15)
      .attr("fill", (d) => fuelTypeColors[d]);

    legend
      .append("text")
      .attr("x", 20)
      .attr("y", 12)
      .attr("fill", "grey")
      .text((d) => d.replace(/_/g, " ").replace("GAS ", ""));

    // Adjust title position
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top + 20) // Adjusted position
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text(title)
      .attr("fill", "grey");
  }, [data, metricField, title]);

  useEffect(() => {
    !isLoading && renderChart();
  }, [renderChart, isLoading]);

  useEffect(() => {
    const handleResize = () => {
      !isLoading && renderChart();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;
  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
};

export default GenerationByFuelTypeGraph;
